body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* first image section css */
.image1 {
  min-height: 85vh;
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))
}

.image1 .content1 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

/* event box updates css */

.event-box {
  padding: 5.5rem 1rem;
  position: relative;
  margin-top: 20px
;
}

.event-box .event-title {
  text-transform: uppercase;
  padding: 20px
  /* font-size: 3rem; */
  /* position: absolute;  */
  /* top: 0; */
  /* text-align: center; */
}

.event-box .event_img {

  /* width: 600px; */
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 70%;
}

.content1 span.img-txt {
  text-transform: uppercase;
  position: absolute;
  top: 30%;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  text-align: center;
  color: whitesmoke;
  font-size: 5rem;
  font-weight: 500;
  font-weight: bold; 
  text-shadow: -1px -1px 0 red, 
               1px -1px 0 orange, 
               -1px 1px 0 yellow, 
               1px 1px 0 blue;
}



/* third image css */

.image3 {
  /* opacity: 0.5; */
  min-height: 60vh;
  position: relative;
  
}

.image3 .content1 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}


.social-media {
  color: gray;
  float: right;
  /* text-align: right; */
}

.socials{
  color: grey;
  font-size: 50px;
  margin-right: 10px; 
}

.footer-container {
  padding: 5.5rem 1rem;

}

/* 
.imageabout {

  min-height: 40vh;
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))
}

.imageabout .content {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 9vh;
} */