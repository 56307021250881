
  /* default styles */
body, h1, h2, h3, p, ul, li {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: Arial, sans-serif;
    color: #333;
  }
  



  
  /* home section styles */
  .home {
    background-color: #f4f4f4;
    padding: 4rem 0;
    text-align: center;
  }
  
  .home-content {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .home h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .home p {
    font-size: 1.2rem;
  } */
  


  .about {
    max-width: 600px;
    margin: 2rem auto;
    padding: 1rem;
    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .about h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .about p {
    font-size: 1.1rem;
  }
